@import "../../../../../assets/styles/variables.scss";

.textarea-wrapper {
  display: flex;
  flex-direction: column;

  textarea {
    padding: 10px;
    color: $text-color;
    font-weight: normal;
    font-family: $global-font-family;
    font-style: normal;
    border: 1px solid $border-color;
    border-radius: 8px;
    resize: none;
  }

  textarea:hover,
  textarea:active,
  textarea:focus {
    outline: none;
  }

  textarea::placeholder {
    color: rgba(24, 25, 32, 0.4);
    font-size: 14px;
  }

  label,
  .textArea_details_character-counter {
    padding-left: 10px;
    color: $gray;
    font-size: 12px;
    line-height: 30px;
  }

  .textArea_details_character-counter {
    float: right;
  }
}
