@import "src/assets/styles/common";

.form-header {
  @include flex(space-between);

  margin-bottom: 30px;
  .form-header-title {
    @include flex();

    .title {
      font-weight: bold;
      font-size: $font-size-title;
    }
    .back-button {
      margin-right: 17px;
    }
  }
  .form-header-buttons {
    @include flex(flex-end);

    font-weight: 500;
    .form-header-delete {
      cursor: pointer;
      &.delete {
        color: $red;
      }
      &.restore {
        color: $blue;
      }
    }
    .form-header-cancel {
      margin-left: 15px;
      font-size: $font-size-normal;
      cursor: pointer;
    }
    .form-header-submit {
      button {
        @include flex();

        min-width: 110px;
        height: 40px;
        margin-left: 20px;
        padding: 0 13px;
        &.import-submit {
          display: block;
          background: $import-gradient;
        }
      }
    }
    .vertical-divider {
      width: 1px;
      height: 30px;
      margin: 0 0 0 20px;
      &:last-child {
        display: none;
      }
    }
  }
}
