@import "src/assets/styles/common";

.training-certificate-wrapper {
  .training-certificate-content {
    @include flex(space-between);

    padding: 10px;
    background: #f4f4f4;
    border-radius: 10px;
  }
  .training-certificate-title,
  .training-certificate-subtitle {
    color: $gray;
    font-size: $font-size-small;
  }
  .training-certificate-value {
    font-size: $font-size-hint;
  }
  .training-certificate-column {
    &:last-child {
      text-align: right;
    }
  }
}
