@import "../../../../../assets/styles/mixins.scss";
@import "../../../../../assets/styles/variables.scss";

$auth-modal-background: #fff;
$auth-modal-width: 560px;

.auth-modal {
  position: relative;
  width: $auth-modal-width;
  padding: 80px 50px;
  background-color: $auth-modal-background;
  border-radius: $main-border-radius;
  .auth-back-button {
    @include flex(flex-start);

    margin-bottom: 20px;
    color: $text-color;
    font-weight: 500;
    font-size: $font-size-normal;
    cursor: pointer;
    .icon.back {
      width: 7px;
      height: 16px;
      margin-right: 8px;
      margin-bottom: 2px;
    }
  }
  .auth-modal-title {
    margin-bottom: 50px;
    font-weight: 900;
    font-size: $font-size-huge;
  }
  &::after {
    @include backgroundImage("/assets/files/images/auth/auth-logo.png");

    position: absolute;
    top: -45px;
    right: -30px;
    display: block;
    width: 100px;
    height: 110px;
    background-size: contain;
    content: "";
  }
  button[type="submit"] {
    width: 100%;
    margin-top: 50px;
  }
  .custom-link {
    margin-top: 20px;
  }
  .auth-modal-hint {
    margin-bottom: 10px;
    font-size: $font-size-hint;
  }
}
