@import "./items/index.scss";
@import "./variables.scss";
@import "./forms.scss";
@import "../fonts/roboto.css";

body {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  margin: 0;
  color: $text-color;
  font-family: $global-font-family;
  background-color: $main-background-color;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  &:link {
    color: $blue;
  }
}

button {
  font-family: $global-font-family;
}

.custom-link a {
  color: $accent-color2;
  font-weight: 400;
  font-size: $font-size-normal;
  text-decoration: none;
}

.clearfix::after {
  display: table;
  clear: both;
  content: "";
}
.clearfix {
  overflow: auto;
}
