@import "src/assets/styles/common";

$checkmark-bg: linear-gradient(62.22deg, #e4611e 17.77%, #ff792b 94.43%);
$checkmark-size: 20px;
$disabled-checkbox-color: $border-color;

.checkbox-input {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  cursor: pointer;

  label {
    font-weight: normal;
    font-size: $font-size-hint;
    font-style: normal;
    cursor: pointer;
    @include flex();
  }

  input {
    display: none;
  }

  .checkmark {
    position: relative;
    display: inline-block;
    width: $checkmark-size;
    height: $checkmark-size;
    margin-right: 10px;
    border: 1px solid $border-color;
    border-radius: 5px;
  }

  .checkmark::after {
    position: absolute;
    top: 3px;
    left: 6px;
    display: none;
    box-sizing: content-box;
    width: 3px;
    height: 7px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    content: "";
  }

  &.disabled {
    cursor: not-allowed;
    .checkmark {
      background-color: $light-gray;
      cursor: not-allowed;
    }
    .heading {
      color: $disabled-checkbox-color;
      cursor: not-allowed;
    }
  }

  &.checked {
    .checkmark:not(.disabled) {
      background: $checkmark-bg;
      border-color: #ef5a50;
    }

    .checkmark::after {
      display: block;
      box-sizing: content-box;
    }

    .disabled {
      background: $gray;
      background-color: $gray;
      border: 1px solid $gray;
      cursor: not-allowed;
    }
  }
}
