@import "../../mixins";

.icon {
  width: 100%;
  height: 100%;
  cursor: pointer;

  &.notification-error {
    @include backgroundImage("/assets/files/icons/notification/notification-error.svg");
  }

  &.notification-success {
    @include backgroundImage("/assets/files/icons/notification/notification-success.svg");
  }

  &.upload {
    @include backgroundImage("/assets/files/icons/general/upload.svg");
  }

  &.back {
    @include backgroundImage("/assets/files/icons/general/back.svg");
  }

  &.lock {
    @include backgroundImage("/assets/files/icons/general/lock.svg");
  }

  &.close {
    @include backgroundImage("/assets/files/icons/general/close.svg");
  }

  &.find {
    @include backgroundImage("/assets/files/icons/general/find.svg");
  }
  &.clear {
    @include backgroundImage("/assets/files/icons/general/clear.svg");
  }
  &.edit {
    @include backgroundImage("/assets/files/icons/general/edit.svg");

    width: 20px;
    height: 20px;
  }
  &.back-white {
    @include backgroundImage("/assets/files/icons/general/back-white.svg");
  }
  &.chevron-orange {
    @include backgroundImage("/assets/files/icons/general/chevron-orange.svg");
  }
  &.back-gray {
    @include backgroundImage("/assets/files/icons/general/back-gray.svg");
  }
  &.delete-white {
    @include backgroundImage("/assets/files/icons/general/delete-white.svg");
  }
  &.delete {
    @include backgroundImage("/assets/files/icons/general/delete.svg");
  }
  &.edit-white {
    @include backgroundImage("/assets/files/icons/general/edit-white.svg");
  }
  &.edit-orange {
    @include backgroundImage("/assets/files/icons/general/edit-orange.svg");
  }

  &.front-orange {
    @include backgroundImage("/assets/files/icons/general/front-orange.svg");
  }

  &.refresh {
    @include backgroundImage("/assets/files/icons/general/refresh.svg");
  }

  &.filter {
    @include backgroundImage("/assets/files/icons/general/filter.svg");
  }

  &.calendar {
    @include backgroundImage("/assets/files/icons/general/calendar.svg");
  }

  &.menu {
    @include backgroundImage("/assets/files/icons/general/menu.svg");
  }

  &.option-selected-green {
    @include backgroundImage("/assets/files/icons/general/option-selected-green.svg");
  }

  &.calendar-orange {
    @include backgroundImage("/assets/files/icons/general/calendar-orange.svg");
  }

  &.menu-orange {
    @include backgroundImage("/assets/files/icons/general/menu-orange.svg");
  }

  &.chevron-top-blue {
    @include backgroundImage("/assets/files/icons/general/chevron-top-blue.svg");
  }

  &.double-check-green {
    @include backgroundImage("/assets/files/icons/general/double-check-green.svg");

    width: 20px;
    height: 20px;
  }
  &.check-orange {
    @include backgroundImage("/assets/files/icons/general/check-orange.svg");

    width: 20px;
    height: 20px;
  }

  &.check-green {
    @include backgroundImage("/assets/files/icons/general/check-green.svg");

    width: 20px;
    height: 20px;
  }

  &.double-check-white {
    @include backgroundImage("/assets/files/icons/general/double-check-white.svg");

    width: 20px;
    height: 20px;
  }
  &.check-white {
    @include backgroundImage("/assets/files/icons/general/check-white.svg");

    width: 20px;
    height: 20px;
  }

  &.chevron-bottom-gray {
    @include backgroundImage("/assets/files/icons/general/chevron-bottom-gray.svg");
  }

  &.marker {
    @include backgroundImage("/assets/files/icons/general/marker.svg");
  }

  &.document {
    @include backgroundImage("/assets/files/icons/general/document.svg");
  }

  &.clock {
    @include backgroundImage("/assets/files/icons/general/clock.svg");
  }

  &.warning {
    @include backgroundImage("/assets/files/icons/general/warning.svg");
  }

  &.letter {
    @include backgroundImage("/assets/files/icons/general/letter.svg");
  }

  &.logout {
    @include backgroundImage("/assets/files/icons/general/logout.svg");
  }

  &.sort {
    @include backgroundImage("/assets/files/icons/sort/sort.svg");
  }

  &.sort-asc {
    @include backgroundImage("/assets/files/icons/sort/sort-asc.svg");
  }

  &.sort-desc {
    @include backgroundImage("/assets/files/icons/sort/sort-desc.svg");
  }

  // Menu icons
  &.activities {
    @include backgroundImage("/assets/files/icons/menu/activities.svg");
  }
  &.alerts {
    @include backgroundImage("/assets/files/icons/menu/alerts.svg");
  }
  &.assignments {
    @include backgroundImage("/assets/files/icons/menu/assignments.svg");
  }
  &.documents {
    @include backgroundImage("/assets/files/icons/menu/documents.svg");
  }
  &.header-icon {
    @include backgroundImage("/assets/files/icons/menu/header-icon.svg");
  }
  &.jsa {
    @include backgroundImage("/assets/files/icons/menu/jsa.svg");
  }
  &.members {
    @include backgroundImage("/assets/files/icons/menu/members.svg");
  }
  &.menu-toggle {
    @include backgroundImage("/assets/files/icons/menu/menu-toggle.svg");
  }
  &.notifications {
    @include backgroundImage("/assets/files/icons/menu/notifications.svg");
  }
  &.projects {
    @include backgroundImage("/assets/files/icons/menu/projects.svg");
  }
  &.settings {
    @include backgroundImage("/assets/files/icons/menu/settings.svg");
  }
  &.tasks {
    @include backgroundImage("/assets/files/icons/menu/tasks.svg");
  }
  &.teams {
    @include backgroundImage("/assets/files/icons/menu/teams.svg");
  }
  &.trainings-and-certifcates {
    @include backgroundImage("/assets/files/icons/menu/trainings-and-certifcates.svg");
  }
  &.users {
    @include backgroundImage("/assets/files/icons/menu/users.svg");
  }
  &.user {
    @include backgroundImage("/assets/files/icons/menu/user.svg");
  }
}
