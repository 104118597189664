@import "src/assets/styles/common";
@import "src/assets/styles/z-index";

.search-body {
  position: relative;
  .autocomplete-variants {
    position: absolute;
    top: 72px;
    z-index: map-get($z-indexes, high1);
    width: 100%;
    background-color: white;
    border: 1px solid $border-color;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.07);
    .autocomplete-variant {
      padding: 10px;
      cursor: pointer;
      &:hover {
        background-color: #ddeaff;
      }
      &_no_matches_found {
        color: $gray;
        text-align: center;
      }
    }
  }
}
