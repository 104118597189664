@import "src/assets/styles/common";

$avatar-size: 40px;

.team-member-selector-row {
  @include flex(flex-start);

  width: 100%;
  min-height: 60px;
  .team-member-main-cell {
    width: 50%;
    max-width: 50%;
    @include flex(flex-start);
    .team-member-avatar {
      width: $avatar-size;
      min-width: $avatar-size;
      height: $avatar-size;
      margin-right: 10px;
      background-color: #e3e3e3;
      background-size: 40%;
      border-radius: 50%;
      @include backgroundImage("/assets/files/icons/general/person.svg");
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }
    .team-member-info {
      width: calc(100% - #{$avatar-size + 15px});
    }
    .team-member-name {
      @include truncate-line();

      max-width: 100%;
    }
    .team-member-position {
      line-height: 20px;
    }
  }
  .team-member-teams-cell {
    flex-wrap: wrap;
    width: 20%;
  }
  .team-member-certificate {
    width: 35%;
    min-width: 35%;
    color: $red;
    font-size: $font-size-small;
  }
  .team-member-teams-cell,
  .team-member-position {
    color: $gray;
    font-size: $font-size-small;
  }
  .team-member-selected-marker {
    min-width: 20px;
    .icon {
      width: 20px;
      height: 10px;
    }
  }
}
