@import "src/assets/styles/common";

.label-array-wrapper {
  .label-array-title {
    margin-top: 0.8em;
    margin-bottom: 1.3em;
  }
  .label-array-item {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 8px 10px;
    background-color: $light-gray;
    border-radius: $main-border-radius;
    cursor: pointer;
  }
}
