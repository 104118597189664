@import "src/assets/styles/common";

.no-matches-found {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @include flex(center, center, column);
  .no-matches-icon {
    @include backgroundImage("/assets/files/images/general/no-matches.png");

    width: 180px;
    height: 150px;
    margin-bottom: 20px;
    &.alerts-icon,
    &.documents-icon {
      @include backgroundImage("/assets/files/images/general/no-matches-documents.svg");
    }
    &.alerts-icon {
      @include backgroundImage("/assets/files/images/general/no-matches-alerts.svg");
    }
    &.paper-icon {
      @include backgroundImage("/assets/files/images/general/no-matches-table-paper.svg");
    }
    &.workers-icon {
      @include backgroundImage("/assets/files/images/general/no-matches-members.svg");
    }
    &.certificates-icon {
      @include backgroundImage("/assets/files/images/general/no-matches-certificates.svg");
    }
    &.trainings-icon {
      @include backgroundImage("/assets/files/images/general/no-matches-trainings.svg");
    }
    &.activities-icon {
      @include backgroundImage("/assets/files/images/general/no-matches-activities.svg");
    }
  }
  .no-matches-label {
    color: $gray;
    font-weight: bold;
    font-size: $font-size-title;
    text-align: center;
  }
}
