@import "src/assets/styles/common";

.autocomplete-select-wrapper {
  .custom-select__multi-value {
    display: none;
  }
  .custom-select__menu-list {
    max-height: 300px;
  }
  .custom-select__option {
    word-break: break-all;
  }
  .multi_select_values_wrapper {
    @include flex(flex-start);

    flex-wrap: wrap;
    margin-top: 10px;
    .multi_select_value_item {
      margin-right: 10px;
      margin-bottom: 5px;
      padding: 10px;
      font-size: $font-size-hint;
      background: rgba($color: #ddd, $alpha: 0.2);
      border-radius: 10px;
      @include flex(flex-start);

      &.is_clickable {
        cursor: pointer;
      }
      &.is_locked {
        color: $gray;

        .lock-icon {
          width: 25px;
          height: 20px;
        }
      }
    }
    .multi_select_value_close {
      margin-left: 10px;
      cursor: pointer;
      .icon {
        width: 20px;
        height: 20px;
      }
    }
  }
  .light-options-style {
    .custom-select__option {
      cursor: pointer;
    }
    .custom-select__option--is-selected {
      position: relative;
      color: $blue;
      background-color: #f0f3fc;
      cursor: pointer;
      &::after {
        position: absolute;
        right: 10px;
        width: 16px;
        height: 16px;
        content: "";
        @include backgroundImage("/assets/files/icons/general/option-selected.svg");
      }
    }
  }
  .custom-multiselect-value {
    margin-right: 5px;
    color: $gray;
  }
}
