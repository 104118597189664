@import "../../assets/styles/common.scss";

.app-container {
  @include flex(flex-start);

  .app-content {
    width: calc(100% - #{$sidebar-width});
    margin-left: $sidebar-width;
    padding: 20px 20px 0 20px;
    .page-container {
      position: relative;
      min-height: calc(100vh - 100px);
    }

    transition: width 0.25s, margin-left 0.25s;

    &.extended {
      width: calc(100% - 60px);
      margin-left: 60px;
    }
  }
}
