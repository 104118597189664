@import "src/assets/styles/common.scss";

$table-border-color: $border-color;

.table-row {
  @include flex(flex-start, stretch);

  font-size: $font-size-hint;
  background-color: rgba($color: $table-border-color, $alpha: 0.2);
  &:nth-child(even) {
    background-color: white;
  }
  &:last-child {
    border-radius: 0 0 10px 10px;
  }
  .table-row-cell {
    flex-grow: 1;
    width: 100%;
    min-height: 50px;
    padding: 5px 30px;
    overflow: hidden;
    word-break: break-word;
    @include flex(flex-start);
    &:not(:last-child) {
      border-right: 1px solid $table-border-color;
    }
    &.cell-space-between {
      @include flex(space-between);
    }
  }
  .appear-click-block {
    display: flex;
    width: 50px;
    margin-left: 25px;
  }
}
