@import "src/assets/styles/common";

.no-matches-table-wrapper {
  position: relative;
  padding-top: $table-header-height;
  border-radius: 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.07);
  .no-matches-table-header {
    @include flex(flex-start);

    position: absolute;
    top: 0;
    width: 100%;
    height: $table-header-height;
    padding-right: 6px;
    color: $gray;
    font-size: $font-size-small;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.07);
  }
  .no-matches-table-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 200px);
    background: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.07);
    .no-matches-table-icon {
      @include backgroundImage("/assets/files/images/general/no-matches.png");

      width: 180px;
      height: 150px;
      background-position: center;
      background-size: contain;
      &.paper-icon {
        @include backgroundImage("/assets/files/images/general/no-matches-table-paper.svg");
      }
      &.workers-icon {
        @include backgroundImage("/assets/files/images/general/no-matches-members.svg");
      }
      &.certificates-icon {
        @include backgroundImage("/assets/files/images/general/no-matches-certificates.svg");
      }
      &.trainings-icon {
        @include backgroundImage("/assets/files/images/general/no-matches-trainings.svg");
      }
      &.activities-icon {
        @include backgroundImage("/assets/files/images/general/no-matches-activities.svg");
      }
      &.jsa-icon {
        @include backgroundImage("/assets/files/images/general/no-matches-table.svg");
      }
    }
    .no-matches-table-label {
      margin: 20px 0;
      color: $gray;
      font-weight: bold;
      font-size: $font-size-title;
    }
    .no-matches-table-text {
      width: 320px;
      color: $gray;
      font-size: $font-size-subtitle;
    }
  }
}
