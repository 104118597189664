@import "../../../../assets/styles/mixins";

.auth-container {
  @include backgroundImage("/assets/files/images/auth/auth-bg.png");

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  height: 100vh;
  padding-left: 150px;
  background-position: center;
  background-size: cover;
  opacity: 0.9;
  mix-blend-mode: multiply;
}
