@import "../../../../../assets/styles/variables";
@import "../../../../../assets/styles/mixins";

.react-toast-notifications__container {
  .notification {
    width: 400px;
    margin: 20px;
    padding: 20px 15px;
    font-family: $global-font-family;
    background: $notification-bg;
    border-radius: 10px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);

    .notification-content {
      display: flex;
      .notification-content-left {
        display: flex;
        max-width: 330px;
        padding-right: 20px;

        .notification-content-left-icon {
          display: flex;
          flex-basis: 30px;
          flex-grow: 0;
          flex-shrink: 0;
          align-items: center;
          justify-content: center;
          width: 30px;
          height: 30px;
          margin-right: 10px;
          border-radius: 50%;

          &.error {
            background: rgba(235, 87, 87, 0.2);
          }

          &.success {
            background: rgba(55, 198, 138, 0.2);
          }

          .icon {
            width: 20px;
            height: 20px;
          }
        }

        .notification-content-left-info {
          width: 240px;
        }

        .notification-title {
          font-weight: bold;
          font-size: 24px;
        }

        .notification-message {
          margin-top: 5px;
          font-size: 14px;
        }
      }

      .notification-content-right {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 15px;
        color: #9e9e9e;
        text-align: center;
        border-left: 1px solid #ddd;

        div {
          cursor: pointer;
        }
      }
    }

    &.error {
      color: $notification-error;
      border-left: 5px solid $notification-error;
    }

    &.success {
      color: $notification-success;
      border-left: 5px solid $notification-success;
      .notification-message {
        color: $main-color;
      }
    }
  }
}
