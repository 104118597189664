@import "src/assets/styles/common";

.add-select-option {
  @include flex(space-between);

  margin: 0 10px 10px 10px;
  padding-top: 10px;
  border-top: 1px solid #ddd;
  .add-select-option-hint {
    color: $gray;
    font-size: $font-size-hint;
  }
  .add-select-option-button {
    @include flex();

    color: $accent-color2;
    font-weight: bold;
    cursor: pointer;
  }
  .icon {
    width: 20px;
    height: 15px;
    margin-top: 3px;
  }
}
