@import "src/assets/styles/variables.scss";

.status-label {
  font-size: $font-size-hint;
  &.Active,
  &.Past {
    color: $active-color;
  }
  &.Inactive,
  &.Archived {
    color: $inactive-color;
  }
  &.Invited {
    color: $invited-color;
  }
  &.Deactivated {
    color: $deactivated-color;
  }
  &.Draft,
  &.Scheduled {
    color: $draft-color;
  }
  &.In-Progress {
    color: $orange;
  }
  &.Scheduled {
    color: $blue;
  }
  &.Expired,
  &.Missed {
    color: $red;
  }
  &.Valid,
  &.Completed,
  &.Passed,
  &.Sent {
    color: $green;
  }
}
