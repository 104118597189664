@import "src/assets/styles/common";

.note-form {
  display: flex;
  flex-direction: column;
  font-family: $global-font-family;

  .submit-wrapper {
    width: 100%;
    margin-bottom: -20px;
    @include flex(space-between);
    button {
      width: 49%;
      height: 40px;
      padding: 0;
    }
  }

  .error-message {
    margin-top: -16px;
  }

  textarea {
    height: 220px;
    margin-bottom: 20px;
    background-color: white;
    border: 1px solid #ddd;
  }
}
