@import "src/assets/styles/common";

.user-notifications-list {
  @include customScrollbar();

  padding: 15px 0;
  overflow-y: auto;
  .user-notifications-list__item:not(:last-child) {
    border-bottom: 1px solid $border-color;
  }
}
