@import "src/assets/styles/common";

.training-team-member-card {
  margin-bottom: 1em;
  padding: 0.6em 0.8em;
  font-size: 0.9em;
  background-color: $light-gray;
  border-radius: $main-border-radius;
  cursor: pointer;
  .training-team-member-card-member-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.3em 0;
    p {
      margin-top: 0;
      margin-bottom: 0.4em;
      color: $gray;
      font-size: 0.8em;
    }
    .member,
    .position {
      width: 32%;
      min-height: 35px;
    }
    .status-container {
      width: 36%;
      text-align: right;
    }
    .training-signature {
      width: 38px;
      height: 28px;
      margin-left: 18px;
      img {
        width: 100%;
      }
    }
  }
  .training-member-status {
    font-size: $font-size-hint;
    &.missed {
      color: $red;
    }
    &.scheduled {
      color: $blue;
    }
    &.completed {
      color: $green;
    }
    &.confirmed {
      color: $orange;
    }
  }
  .training-member-status-hint {
    margin-top: 10px;
  }
  .training-member-button {
    @include flex(flex-end);

    margin-top: 10px;
    padding-top: 10px;
    font-size: $font-size-normal;
    border-top: 1px solid $border-color;
    button {
      @include flex();
    }
    .mark-complete {
      color: $green;
    }
    .mark-confirmed {
      color: $orange;
    }
    .check-icon {
      width: 18px;
      height: 18px;
      margin-right: 5px;
    }
    .training-pass-confirmed-hint {
      color: $gray;
      font-size: $font-size-small;
    }
  }
}
