@import "src/assets/styles/common";

$table-height: 380px;

.team-members-modal {
  padding: 0;
  .modal-body {
    margin-bottom: 0;
  }
  .team-member-modal-wrapper {
    width: 100%;
    @include flex(default, stretch, column);

    .team-member-modal-header {
      @include flex(space-between);

      z-index: 1;
      height: 60px;
      padding: 0 10px 0 20px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.07);
      .team-member-modal-title-wrapper {
        @include flex(space-between);

        flex-grow: 1;
        margin-right: 10px;
        .custom-input {
          height: 40px;
          font-size: 14px;
        }
      }
      .team-member-modal-title {
        color: $orange;
        font-weight: bold;
        font-size: $font-size-title;
        font-family: $global-font-family;
      }
      .team-member-modal-filter {
        @include flex();
        .custom-select {
          width: 180px;
        }
        .team-member-modal-cancel-icon {
          width: 40px;
          height: 40px;
          margin-left: 10px;
          background-color: #f1f1f1;
          border-radius: 10px;
        }
      }
    }

    .team-member-modal-content {
      height: $table-height;
      .table-wrapper {
        max-height: $table-height;
        padding-top: 0;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        box-shadow: none;
        .table-content {
          max-height: $table-height;
          .table-row {
            margin: 0 10px;
            background-color: #fff;
            border-bottom: 1px solid $border-color;
            &:hover {
              background-color: #f0f3fc;
            }
            &:last-child {
              border-bottom: none;
              border-radius: 0;
            }
            .table-row-cell {
              padding: 0 20px;
            }
          }
        }
      }
      .table-header {
        display: none;
      }
      &__no-matches-icon {
        @include backgroundImage("/assets/files/images/general/no-matches-members.svg");

        width: 140px;
        height: 150px;
      }
      .no-matches-label {
        font-size: 1.1em;
      }
    }

    .team-member-modal-footer {
      @include flex(space-between);

      z-index: 1;
      height: 80px;
      padding: 0 20px;
      font-size: $font-size-normal;
      box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.07);
      .team-member-modal-footer-selected {
        color: $main-color;
        font-size: $font-size-subtitle;
      }
      .team-member-modal-footer-buttons {
        @include flex(flex-end);
      }
      .team-member-plain-button {
        margin-right: 20px;
        color: $main-color;
        font-weight: 500;
        cursor: pointer;
        &.unselect-all {
          color: $blue;
        }
        &.select-all {
          color: $orange;
        }
        &.disabled {
          color: $gray;
          cursor: not-allowed;
        }
      }
      .team-member-modal-assign {
        width: 100px;
        height: 40px;
        padding: 0;
      }
      .vertical-divider {
        height: 20px;
        margin: 0;
      }
    }
  }
}
