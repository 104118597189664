@import "src/assets/styles/common";

.notes-block {
  .notes-list {
    margin-top: 10px;
  }

  .empty-list {
    padding: 10px 0;
    color: #ddd;
    font-weight: 700;
    font-size: 24px;
    font-family: $global-font-family;
    line-height: 32px;
    text-align: center;
    background-color: rgba(221, 221, 221, 0.2);
    border-radius: 10px;
  }

  .notes-header {
    display: flex;
    justify-content: space-between;
    font-family: $global-font-family;

    .left-notes-header {
      color: $invited-color;
      font-weight: 400;
      font-size: 18px;
    }

    .right-notes-header {
      color: $accent-color2;
      font-weight: 500;
      font-size: 16px;
      cursor: pointer;
    }
  }
}
