@import "../../../../assets/styles/common.scss";

.table-header {
  @include flex(flex-start);

  position: absolute;
  top: 0;
  width: 100%;
  height: $table-header-height;
  padding-right: 6px;
  color: $gray;
  font-size: $font-size-small;
  background-color: white;
  border-radius: 10px 10px 0 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.07);
  .table-header-cell {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    width: 100%;
    padding: 10px 30px;
  }
}
