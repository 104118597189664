@import "./variables.scss";

.form-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .block {
    width: 100%;
  }

  .block:first-child {
    margin-right: 10px;
  }

  .block:last-child {
    width: 100%;
    margin-left: 10px;
  }
}

.form-subtitle {
  margin: 20px 0;
  font-size: $font-size-subtitle;
}

.form-additional-status {
  @include flex(space-between);

  padding: 20px 0;
  .entity-status {
    @include flex();
    & > div:first-child {
      margin-right: 20px;
    }
  }
  .entity-update-status {
    color: $active-color;
    font-weight: 500;
    font-size: $font-size-normal;
    cursor: pointer;
    &.deactivate {
      color: $red;
    }
    &.resend {
      color: $invited-color;
    }
  }
  .entity-invited-status-button {
    @include flex();
    .resend {
      margin-left: 20px;
    }
  }
}

.form-additional-label {
  margin-right: 5px;
  color: $gray;
  font-size: $font-size-small;
}

.form-check-box-row {
  margin-top: 10px;
}

.form-subtitle-wrapper {
  @include flex(space-between);

  .form-subtitle-right-wrapper {
    @include flex(flex-start);
  }

  font-family: $global-font-family;
  .form-subtitle-title {
    color: $invited-color;
    font-weight: 400;
    font-size: $font-size-subtitle;
  }
  .form-subtitle-add,
  .form-subtitle-clear {
    color: $accent-color2;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
    &.disabled {
      color: $gray;
      cursor: not-allowed;
    }
  }
  .form-subtitle-clear {
    margin-right: 25px;
    color: $gray;
  }
}
