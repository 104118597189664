@import "src/assets/styles/common";

$content-height: 330px;

.image-select-modal {
  padding: 0;
  .modal-body {
    margin-bottom: 0;
  }
  .image-select-modal-wrapper {
    width: 100%;
    @include flex(default, stretch, column);

    .image-select-modal-header {
      @include flex(space-between);

      z-index: 1;
      height: 60px;
      padding: 0 10px 0 20px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.07);
      .image-select-modal-header-title {
        font-weight: bold;
        font-size: $font-size-title;
      }
      .image-select-modal-header-close {
        @include flex();

        width: 40px;
        height: 40px;
        background-color: #f1f1f1;
        border-radius: $main-border-radius;
        cursor: pointer;
        .icon {
          width: 15px;
          height: 15px;
        }
      }
    }

    .image-select-modal-content {
      flex-wrap: wrap;
      height: $content-height;
      overflow-y: auto;
      @include flex(space-between);
      @include customScrollbar();
      .image-select-template {
        @include flex();

        width: 60px;
        height: 60px;
        margin: 10px;
        padding: 5px;
        border-radius: 8px;
        cursor: pointer;
        &.selected {
          border: 1px solid $orange;
        }
        img {
          width: 35px;
          height: 35px;
        }
      }
    }

    .image-select-modal-footer {
      @include flex(space-between);

      z-index: 1;
      height: 80px;
      padding: 0 20px;
      font-size: $font-size-normal;
      box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.07);
      .image-select-modal-save {
        width: 100px;
        height: 40px;
        padding: 0;
      }
      .image-select-upoad {
        @include flex();

        color: $orange;
        font-weight: 500;
        font-size: $font-size-normal;
        cursor: pointer;
        .icon {
          width: 15px;
          height: 15px;
          margin-right: 8px;
        }
      }
    }
  }
}
