@import "src/assets/styles/common.scss";

.container-header {
  @include flex(space-between);

  margin-bottom: 20px;
  .container-header-title {
    font-weight: bold;
    font-size: $font-size-title;
    @include flex();
    .container-header-subtitle {
      margin-left: 10px;
      color: $gray;
      font-weight: normal;
      font-size: 18px;
    }
  }
  .custom-input {
    height: 40px;
    margin-right: 10px;
    font-size: $font-size-hint;
  }
  .custom-select {
    min-width: 150px;
  }
}
