@import "src/assets/styles/common";

.form-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 12px;
  color: $orange;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  text-transform: capitalize;
  .icon {
    width: 15px;
    height: 16px;
  }
  &:hover {
    cursor: pointer;
  }
}
