@import "src/assets/styles/common";

.custom-select-menu-list {
  .custom-select-list {
    max-height: 200px;
    overflow-y: auto;
    @include customScrollbar();
  }
  .custom-select__menu-list {
    overflow: visible;
  }
}
