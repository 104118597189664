@import "src/assets/styles/common";

.note-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  font-family: $global-font-family;
  background: rgba(221, 221, 221, 0.2);
  border-radius: 10px;

  .note-item-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 7px 10px 15px;
    color: #9e9e9e;
    font-size: 12px;

    .left-note-item-header {
      display: flex;
      align-items: center;
      .note-date {
        padding-left: 5px;
      }

      .note-user {
        padding-right: 5px;
      }
    }

    .right-note-item-header {
      width: 20px;
    }
  }

  .note-item-content {
    padding: 0 15px 10px 15px;
    color: #292929;
    font-size: 14px;
    font-family: $global-font-family;
    word-break: break-all;
  }
}
