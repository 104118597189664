@import "src/assets/styles/common";

$image-input-size: 60px;
$image-border-color: #ddd;

.image-multi-select-wrapper {
  margin-top: 10px;
  .image-multi-select-label {
    margin-bottom: 10px;
    color: $gray;
    font-size: $font-size-small;
  }
  .image-multi-select-images {
    @include flex(flex-start);
    .image-wrapper,
    .image-add-block {
      width: $image-input-size;
      height: $image-input-size;
      border-radius: 8px;
    }
    .image-wrapper {
      @include flex();

      position: relative;
      margin-right: 10px;
      padding: 5px;
      background-color: white;
      border: 1px solid $image-border-color;
      img {
        max-width: 100%;
        max-height: 100%;
        border-radius: 8px;
      }
      .image-controls {
        position: absolute;
        top: -5px;
        right: -5px;
        cursor: pointer;
        .image-delete {
          @include flex();

          width: 18px;
          height: 18px;
          background-color: $image-border-color;
          border-radius: 50%;
          .icon {
            width: 7px;
            height: 7px;
          }
        }
      }
    }

    .image-add-block {
      @include flex();

      background-color: $light-gray;
      cursor: pointer;
      .image-add-label {
        color: $gray;
        font-size: 22px;
      }
    }
  }
}
