.contact-us-form {
  .contact-us-form-submit {
    float: right;
    height: 40px;
    padding: 0 22px;
  }
  .message-field {
    margin-bottom: 1.2em;
  }
}
