@import "src/assets/styles/common";

.tab-nav-item {
  position: relative;
  height: 60px;
  padding: 0 20px;
  color: #292929;
  font-weight: 500;
  font-size: 16px;
  font-style: normal;
  line-height: 28px;
  background-color: rgba(221, 221, 221, 0.3);
  border-color: rgba(221, 221, 221, 0);
  border-radius: 10px 10px 0 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.07);
  &.active-tab {
    color: $orange;
    background: #fff;
    opacity: 1;
  }
  &.active-tab::before {
    position: absolute;
    top: -2px;
    left: 0;
    width: 100%;
    height: 4px;
    background: linear-gradient(62.22deg, #ef5a50 17.77%, #ff792b 94.43%);
    border-radius: 15px 15px 0 0;
    content: "";
  }
  &:hover {
    cursor: pointer;
  }
}
