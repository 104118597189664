@import "src/assets/styles/variables.scss";

.password-icon-block {
  position: absolute;
  right: 20px;
  bottom: 10px;
  color: $text-color;
  font-weight: 500;
  font-size: $font-size-normal;
  cursor: pointer;
}

.input-wrapper {
  position: relative;
  width: 100%;
  margin-bottom: 15px;
  .help-text {
    padding-top: 4px;
    padding-left: 2px;
    color: $gray;
    font-size: 12px;
  }
  label {
    display: flex;
    align-items: center;
    padding-left: 10px;
    color: $gray;
    font-weight: normal;
    font-size: $font-size-small;
    font-style: normal;
    line-height: 30px;
  }
  .rs-btn {
    border: 1px solid $border-color !important;
    border-radius: 8px;
  }
  input {
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    padding: 10px;
    color: #292929;
    font-size: 14px;
    background-color: white;
    border: 1px solid $border-color;
    border-radius: 8px;
    outline: none;
  }

  input:disabled {
    color: $gray;
    background: $field-bg-disabled;
    cursor: not-allowed;
  }

  label *:focus {
    outline: none;
  }

  .label {
    padding-left: 10px;
    color: $gray;
    font-size: $font-size-small;
  }

  .clear-search {
    position: absolute;
    top: 39px;
    right: 10px;
    width: 15px;
    color: darkgray;
    font-weight: 500;
    text-align: center;
    background: #f4f6fb;
    border: none;
    cursor: pointer;
  }

  &.search {
    margin-bottom: 0;
    input {
      font-size: 14px;
    }
  }

  &.left {
    width: 90%;
    margin-right: 5%;
  }

  &.right {
    width: 90%;
    margin-left: 5%;
  }

  .autocomplete-dropdown-container {
    position: absolute;
    top: 72px;
    z-index: 1;
    width: 100%;
    max-height: 230px;
    overflow-x: auto;
    background: white;
    border-radius: 8px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

    .hint_item {
      box-sizing: border-box;
      width: 100%;
      margin: 0;
      padding: 7px;
      font-weight: normal;
      font-size: 14px;
      font-style: normal;
      line-height: 20px;
      border: 0;
      cursor: pointer;
    }
  }
}
