@import "../../../../assets/styles/common.scss";

.sidebar-menu-item {
  @include flex(flex-start, center);

  width: 100%;
  height: 50px;
  padding-left: 15px;
  color: #fff;
  font-size: $font-size-hint;
  cursor: pointer;
  &.active {
    padding-left: 10px;
    background: $accent-gradient;
    border-left: 5px solid white;
    &.badged::after {
      background-color: white;
    }
  }
  &.badged {
    position: relative;
    padding-right: 26px;
    &::after {
      position: absolute;
      top: 50%;
      right: 15px;
      display: block;
      width: 10px;
      height: 10px;
      margin: -5px;
      background-color: $red;
      border-radius: 50%;
      content: "";
    }
  }
  .icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    margin-left: 5px;
  }
}
