@import "src/assets/styles/common";

$avatar-input-size: 120px;

.avatar-input-wrapper {
  position: relative;
  width: $avatar-input-size;
  height: $avatar-input-size;
  background-color: rgba($color: #ddd, $alpha: 0.2);
  border-radius: 10px;
  &.read-only {
    pointer-events: none;
    .avatar-controls {
      display: none;
    }
  }
  &.company-logo-img {
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
    img {
      -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      -khtml-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
    }
  }
  .avatar-create-block {
    @include flex(center, center, column);

    width: 100%;
    height: 100%;
    color: $gray;
    text-align: center;
    cursor: pointer;
    .avatar-create-label:first-child {
      margin-bottom: 5px;
      font-size: 30px;
    }
    .avatar-create-label {
      font-weight: 500;
      font-size: $font-size-normal;
    }
  }
  img,
  .company-default-logo {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
  .company-default-logo {
    @include backgroundImage("/assets/files/images/general/default-company-logo.svg");
  }
  .avatar-controls {
    @include flex();

    position: absolute;
    right: 0;
    bottom: 0;
    padding: 3px;
    background: rgba($color: $main-color, $alpha: 0.5);
    border-radius: 10px 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.07);
    .avatar-delete {
      margin-right: 3px;
    }
    .icon {
      width: 20px;
      height: 20px;
    }
  }
}
