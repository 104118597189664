@import "src/assets/styles/common";

$avatar-size: 40px;

.certificate-view-wrapper {
  .form-divider {
    margin: 20px 0;
  }
  .label-array-item {
    font-size: $font-size-hint;
  }
  .certificate-view-row {
    @include flex(space-between, flex-start);
    .certificate-subtitle {
      margin-bottom: 10px;
      color: $gray;
      font-size: $font-size-small;
    }
    .certificate-value {
      font-size: $font-size-hint;
    }
    .certificate-view-column.certificate-issued-by {
      min-width: 30%;
      max-width: 50%;
    }
  }
  .certificate-member-wrapper {
    @include flex(flex-start);

    width: 100%;
    margin: 20px 0;
    padding: 10px;
    background-color: #f8f8f8;
    border-radius: 10px;
    cursor: pointer;
    .certificate-member-name {
      font-size: $font-size-hint;
    }
    .certificate-member-job {
      margin-top: 5px;
      color: $gray;
      font-size: $font-size-small;
    }
  }
  .certificate-member-avatar {
    width: $avatar-size;
    min-width: $avatar-size;
    height: $avatar-size;
    margin-right: 10px;
    background-color: #e3e3e3;
    background-size: 40%;
    border-radius: 50%;
    @include backgroundImage("/assets/files/icons/general/person.svg");
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }
}
