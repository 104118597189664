@import "src/assets/styles/common";

.table-wrapper {
  position: relative;
  padding-top: $table-header-height;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.07);
  .table-clickable {
    .table-row {
      .table-show-onhover {
        display: none;
      }
      .checkbox-show-onhover {
        width: 20px;
        visibility: hidden;
        &.checked {
          visibility: visible;
        }
      }

      cursor: pointer;
      &:hover {
        background-color: #f0f3fc;
        .table-show-onhover {
          display: flex;
        }
        .checkbox-show-onhover {
          visibility: visible;
        }
      }
    }
  }
  .table-content {
    @include customScrollbar();

    overflow-y: scroll;
  }
}
