@import "src/assets/styles/common";

.back-button {
  @include flex();

  color: $gray;
  font-weight: 500;
  font-size: $font-size-normal;
  cursor: pointer;
  .icon {
    width: 17px;
    height: 17px;
  }
}
