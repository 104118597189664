@import "src/assets/styles/common";

.user-notifications {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  .user-notifications-header {
    margin-bottom: 1.2em;
    .user-notifications-info {
      font-size: 0.9em;
    }
    .user-notifications-clear-btn {
      float: right;
    }
  }

  .user-notifications-clear-btn {
    color: $orange !important;
  }

  .user-notifications__list-container {
    position: absolute;
    top: 85px;
    right: 0;
    bottom: 0;
    left: 0;
    &::after,
    &::before {
      position: absolute;
      right: 0;
      left: 0;
      z-index: 1;
      display: block;
      height: 15px;
      background: #fff;
      content: "";
    }
    &::before {
      top: 0;
      background: linear-gradient(0deg, transparent 0%, #fff 100%);
    }
    &::after {
      bottom: 0;
      background: linear-gradient(0deg, #fff 0%, transparent 100%);
    }
  }

  .user-notifications__list {
    position: absolute;
    top: 0;
    right: -20px;
    bottom: 0;
    left: 0;
    padding-right: 20px;
  }
}
