@import "../../../assets/styles/common.scss";

.sidebar {
  @include flex(flex-start, stretch, column);

  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: $sidebar-width;
  height: 100vh;
  color: #fff;
  background-color: $main-background-dark;
  transition-duration: 0.25s;
  transition-property: width;
  .sidebar-header {
    position: relative;
    min-height: 76px;
    padding: 20px 10px;
    border-bottom: 1px solid rgba($color: $gray, $alpha: 0.2);
    @include flex(flex-start);
    .sidebar-logo {
      @include backgroundImage("/assets/files/icons/general/logo.svg");

      display: inline-block;
      width: 26px;
      height: 35px;
      margin-right: 10px;
      margin-left: -10px;
      background-size: contain;
    }
    .sidebar-title {
      @include flex();

      margin: 0 10px;
      font-weight: bold;
      font-size: 18px;
    }
    .sidebar-company-title {
      font-weight: bold;
      font-size: $font-size-normal;
      word-break: break-word;
    }
    .sidebar-company-title-name {
      max-width: 120px;
      font-weight: 500;
      font-size: 14px;
      @include truncate-lines(2);
    }
    .sidebar-company-title-switch {
      margin-top: 5px;
      color: $accent-color2;
      font-weight: 500;
      font-size: $font-size-small;
      cursor: pointer;
    }
    .sidebar-back {
      font-size: 14px;
      @include flex();
      .icon {
        width: 10px;
        height: 16px;
        margin-right: 10px;
      }
    }
  }
  .sidebar-admin-company {
    @include flex();

    justify-content: left;
    min-height: 58px;
    overflow: hidden;
    background-color: rgba($color: $gray, $alpha: 0.1);
    transition: opacity 0.25s, height 0.25s ease 0.25s;
    &-name {
      padding: 0 20px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    transition: min-height 0.25s, opacity 0.25s;
  }
  .sidebar-bottom-content {
    margin-top: auto;
  }
  .sidebar-footer {
    position: relative;
    margin: 0 10px;
    padding: 20px 10px;
    border-top: 1px solid rgba($color: $gray, $alpha: 0.2);

    .user-info {
      @include flex();

      justify-content: left;
      cursor: pointer;
    }

    .user-info-name {
      display: inline-block;
    }

    .avatar {
      min-width: 40px;
      margin-right: 10px;
      margin-left: -10px;
    }
  }

  .toggle-menu-btn {
    position: absolute;
    right: 20px;
    width: 20px;
    height: 20px;
    margin-left: 10px;
  }

  .sidebar-title,
  .sidebar-company-title,
  .sidebar-back,
  .user-info-name,
  .sidebar-menu-item-content {
    opacity: 1;
    transition: opacity 0.25s;
  }

  &.sidebar_closed {
    width: 60px;
    .toggle-menu-btn {
      transform: rotate(180deg);
    }
    .profile-wrapper {
      left: 70px;
    }
    .sidebar-menu-item {
      &.badged::after {
        top: auto;
        top: 18px;
        right: auto;
        left: 33px;
      }
      &.active.badged::after {
        left: 33px;
      }
    }
  }

  &.sidebar_hide-inner-elements {
    overflow: hidden;
    .sidebar-menu-item,
    .user-info {
      min-width: $sidebar-width;
    }
    .sidebar-title,
    .sidebar-admin-company,
    .sidebar-company-title,
    .sidebar-back,
    .user-info-name,
    .sidebar-menu-item-content {
      opacity: 0;
    }
    &.sidebar_closed,
    &.sidebar_opened {
      .sidebar-title,
      .sidebar-admin-company,
      .sidebar-company-title,
      .sidebar-back {
        width: 0;
        height: 0;
        overflow: hidden;
      }
      .sidebar-admin-company {
        min-height: 0;
      }
    }
  }
}

.avatar {
  @include flex();

  display: inline-flex;
  width: 40px;
  height: 40px;
  overflow: hidden;
  color: black;
  color: $main-background-dark;
  background-color: rgba($color: white, $alpha: 0.6);
  border-radius: 50%;
  img {
    height: 100%;
  }
}
