@import "src/assets/styles/common.scss";

$add-button-size: 40px;

.add-button {
  @include flex();

  width: $add-button-size;
  height: $add-button-size;
  font-weight: 300 !important;
  font-size: 30px !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.07);
}
