@import "src/assets/styles/common";

.collapse-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 -0.5em;
  padding: 0.5em;
  border-radius: $main-border-radius;
  cursor: pointer;
  &:hover {
    background-color: $light-gray;
  }
}

.collapse-header-title {
  color: $blue;
  font-size: 1.2em;
}

.collapse-content-wrapper {
  overflow: hidden;
  transition: 0.4s max-height;
}

.collapse-content {
  padding: 0.5em 0;
}

.collapse-toggle {
  width: 20px;
  height: 20px;
  transform: rotate(180deg);
  transform-origin: center;
  transition: 0.4s transform;
  &_rotate {
    transform: rotate(0deg);
  }
}
